<div>
  <div class="h-7 px-3 flex items-center rounded-full border cursor-pointer bg-white" (click)="isAccount = !isAccount">
    <div class="truncate">{{ adAccountSelected?.name }} - ({{ adAccountSelected?.id }})</div>
    <i class="icon-wl-arrow-drop-down text-6"></i>
  </div>
  <p-overlay [(visible)]="isAccount">
    <div class="px-1 py-2 rounded-sm border bg-white shadow-md mt-0.5">
      <div class="h-9 flex items-center pl-9">All ad accounts</div>

      @for (business of accountBusinesses; track $index) {
        <div>
          <div class="text-2 h-8 flex items-center px-2 font-medium text-gray-600">{{ business.name }}</div>
          <div class="flex flex-col gap-2">
            @for (account of business.act_account; track $index) {
              <div
                class="p-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100 rounded-sm"
                (click)="onSelectAdAccount(account)">
                <div class="h-5 w-5 flex items-center justify-center">
                  @if (account.id === adAccountSelected?.id) {
                    <i class="icon-wl-check text-6 text-primary-600"></i>
                  }
                </div>

                <div class="flex flex-col gap-0.5">
                  <span>{{ account.name }}</span>
                  <span class="text-2 text-gray-600">Ad account ID: {{ account.id }}</span>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </p-overlay>
</div>
